<!--  -->
<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container newlist">
            <!-- 头部banner图 -->
            <div class="section_banner_container">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <p class="section_banner_heading">新闻 &amp; 事件</p>
                        <h1>新闻发布</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新闻列表 -->
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-sm-10 col-sm-offset-1">
                  <h1 class="h2" style="text-align: center; font-weight: 700">
                    {{ Newcontent.title }}
                  </h1>
                  <p
                    class="news_date"
                    style="
                      font-size: 20px;
                      margin-bottom: 20px;
                      text-align: center;
                      color: #aeaeae;
                    "
                  >
                    {{ Newcontent.createTime }}
                  </p>
                  <div class="news_content">
                    <article
                      id="post-640"
                      class="post-640 news type-news status-publish hentry"
                    >
                      <div class="entry-content">
                        <div class="vc_row wpb_row vc_row-fluid">
                          <div
                            class="wpb_column vc_column_container vc_col-sm-12"
                          >
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div
                                  class="wpb_text_column wpb_content_element"
                                >
                                  <div class="wpb_wrapper">
                                    <div
                                      style="font-size: 20px"
                                      v-html="Newcontent.content"
                                    ></div>
                                    <br />
                                    <br />
                                    <p
                                      style="
                                        color: #333333;
                                        font-family: 宋体;
                                        font-size: 18px;
                                        text-align: center;
                                      "
                                    >
                                      <strong>【</strong
                                      ><strong>盈喜集团</strong
                                      ><strong>】</strong>
                                    </p>
                                    <p
                                      style="
                                        font-family: 宋体;
                                        text-align: justify;
                                        line-height: 1.8;
                                        font-size: 18px;
                                        color: rgb(51, 51, 51);
                                        text-indent: 2em;
                                      "
                                    >
                                      盈喜集团于2000年进入纺织服装领域，历经近20年的发展，盈喜集团现已成为纺织服装行业内专注于产业链服务的综合性集团公司。2016年，集团成功登陆美国资本市场，市值超20亿美元。作为中国纺织服装行业产业链的探索者、实践者和引领者，盈喜集团秉承资源配置最优化、资源效益最大化的基本思想，从供给侧为全球纺织服装企业专业化、系统化产业链服务，为纺织服装企业发展提供持续动力。
                                    </p>
                                    <p
                                      style="
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: justify;
                                        font-size: 18px;
                                      "
                                    >
                                      <br />
                                    </p>
                                    <p
                                      style="
                                        text-align: center;
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        font-size: 18px;
                                      "
                                    >
                                      <img
                                        src="https://11450065.s21i.faiusr.com/4/ABUIABAEGAAg75DU4wUo1LqPoQMw_wE4_AE.png"
                                        width="251"
                                        height="248"
                                        alt="二维码.png"
                                        style="height: auto; margin: 0 auto"
                                      />
                                    </p>
                                    <br />
                                    <p
                                      style="
                                        text-indent: 0em;
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: center;
                                        font-size: 18px;
                                      "
                                    >
                                      【<strong>关注我们</strong>】
                                    </p>
                                    <p
                                      style="
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: left;
                                        font-size: 18px;
                                      "
                                    ></p>
                                    <p
                                      style="
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: left;
                                        font-size: 18px;
                                      "
                                    >
                                      <strong>盈喜集团股份有限公司</strong>
                                    </p>
                                    <p
                                      style="
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: left;
                                        font-size: 18px;
                                      "
                                    >
                                      地址: 广东省深圳市罗湖区京基100 A座4805
                                    </p>
                                    <p
                                      style="
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: left;
                                        font-size: 18px;
                                      "
                                    >
                                      官方网址: www.addentax.com
                                    </p>

                                    <p
                                      style="
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: left;
                                        font-size: 18px;
                                      "
                                    >
                                      <strong>ADDENTAX GROUP CORP.</strong>
                                    </p>
                                    <p
                                      style="
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: left;
                                        font-size: 18px;
                                      "
                                    >
                                      Address:Rm. 4805, Blk. A, Kingkey 100,
                                      Luohu Dist., Shenzhen, Guangdong Prov.,
                                      China
                                    </p>
                                    <p
                                      style="
                                        color: #333333;
                                        font-family: -apple-system-font,
                                          BlinkMacSystemFont;
                                        text-align: left;
                                        font-size: 18px;
                                      "
                                    >
                                      Web:www.addentax.com
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- .entry-content -->

                      <!-- <footer class="entry-footer"></footer> -->
                      <!-- .entry-footer -->
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "./../components/header/header.vue";
import Footers from "./../components/footer/footer.vue";
import { queryNew } from "../util/api.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  metaInfo() {
    return {
      title: this.Newcontent.title + "-盈喜集团股份有限公司|ATXG",
      meta: [
        { name: "keywords", content: this.Newcontent.title + "-盈喜集团" },
        {
          name: "description",
          content: this.desc,
        },
      ],
    };
  },
  mounted() {},
  data() {
    //这里存放数据
    return {
      // 新闻内容
      Newcontent: "",
      desc: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    takeStr(str) {
      str = str.replace(/\<[^>]*\>(([^<])*)/g, function () {
        let mark = "";
        return arguments[1];
      });
      str = str.substring(0, 120);
      return str;
    },
    // 新闻刷新
    async init() {
      const { data: res } = await this.$http.get(queryNew, {
        params: { id: this.$route.query.id },
      });
      console.log(res);
      this.Newcontent = res.result;
      this.desc = this.takeStr(this.Newcontent.content);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init();
    console.log(this.$route.query.id);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  filters: {
    // 时间格式处理
    formatDate(time) {
      return time.split(" ")[0];
    },
  },
};
</script>
<style lang='less' scoped>
// @import url(''); //引入公共css类
.newlist {
  text-align: left;
  p {
    line-height: 1.5;
  }
  .section_banner {
    background-image: url(../../public/newList/banner.png);
  }
  .newSynopsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>